<template>
  <div id="PersonalUser">
    <div class="content">
      <div class="_content"> 
        <div class="_content_spanLeft">
          <span style="font-size: 15px;text-align: center;display:block;">用户昵称:</span>

          <span style="font-size: 15px;text-align: center;display:block;">{{ User.name }}</span>
        </div>

        <div class="_content_spanRinht">
          <el-button v-show="true" type="text"></el-button
          >
        </div>
      </div>

      <div class="_content">

        <div class="_content_spanLeft">

          <span style="font-size: 15px;text-align: center;display:block;">用户邮箱:</span>

          <span style="font-size: 15px;text-align: center;display:block;">{{ User.email }}</span>

        </div>

        <div class="_content_spanRinht">

          <el-button style="font-size: 15px;" @click="Edit('用户邮箱', User.email, 70)"  type="text">编辑</el-button>

        </div>

      </div>

      <div class="_content">
        <div class="_content_spanLeft">
          <span style="font-size: 15px;text-align: center;display:block;">个人简介:</span>

          <span style="font-size: 15px;text-align: center;display:block;">{{
            User.style
          }}</span>
        </div>

        <div class="_content_spanRinht">
          <el-button style="font-size: 15px;" @click="Edit('个人简介', User.style, 70)"  type="text">编辑</el-button>
        </div>
      </div>

          <div class="_content">
        <div class="_content_spanLeft">
          <span style="font-size: 15px;text-align: center;display:block;">个人头像:</span>

          <span style="font-size: 15px;text-align: center;display:block;">{{
            User.avarct
          }}</span>
        </div>

        <div class="_content_spanRinht">
          <el-button style="font-size: 15px;" @click="Edit('个人简介', User.style, 70)"  type="text">编辑</el-button>
        </div>
      </div>
    </div>
    

    <el-dialog  center="false" :close-on-click-modal="false"   :destroy-on-close="true"  :title="title"  :visible.sync="dialogVisible"  width="33%" >

      <div v-loading="loading">

        <div v-if="dialogVisibleEmail"  class="xgpc-input__wrapper">

          <el-input style="font-size: 15px;"  :type="type" @input="input"  :autosize="autosize"  placeholder="请输入个人简介"    v-model="content"  :maxlength="maxlength"  show-word-limit  >

          </el-input>

        </div>

        <div v-else class="xgpc-input__wrapper_email">

            <el-input  :type="type" @input="input"  :autosize="autosize"  placeholder="请输入替换的邮箱"    v-model="content"  :maxlength="maxlength"  show-word-limit  >

            </el-input>


            <el-input :type="type"  style="width:80%;margin-top: 10px;"  :autosize="autosize"   placeholder="请输入验证码"    v-model="EamilCode"  :maxlength="6"  show-word-limit  >

            </el-input> 


            <el-button style="margin-left: 10px;" @click="sendEmail"  title="发送邮件" type="primary" icon="el-icon-trophy" circle ></el-button> 


            <span class="status">{{ statusMsg }}</span>


        </div>

        <div class="xgpc-input__error">{{errmessage}}</div>

        <div class="xgpc-input__text">

          {{message}}

        </div>

        <div class="UserCenter__profile-edit-modal__btn-group">

          <div  @click="dialogVisible=false" class="Components-UI__Button default">取消</div>

          <div class="Components-UI__Button dangerous" @click="Save" :class="{'disabled': activeTwo }">保存</div>

        </div>

      </div>

    </el-dialog>

  </div>

</template>

<script>

import {IntroductionUpdateuction,SendEmail,IntroductionUpdateuCode} from "../api/Resources";

const message="2-10个汉字，请勿使用包含特殊符号或含有明显营销推广意图的名字";

const message1="良好的简介内容容易收获大家的喜欢。填写建议：个人介绍、内容介绍";

const errmessage="简介仅支持140个以内的数字字母或70个汉字和标点符号";

const errmessage1="简更换绑定邮箱,需要验证码";

export default {

  name: "PersonalUser",

  data() {
    return {

      dialogVisible: false,

      dialogVisibleEmail:false,

      loading:false,

      statusMsg:"",

      title: "",

      content: "",

      EamilCode:"",

      key:"",

      maxlength: 0,

      activeTwo:true,

      type:"text",

      autosize:{ minRows: 3, maxRows: 5},

      placeholder:"",

      message:"",

      errmessage:"",
    };
  },

  computed: {

    User() {return this.$store.getters.User; }
  },

  created() {},

  methods: {

    Edit(spanName, value, maxlength) {

      this.title = spanName;

      this.content = value;

      this.maxlength = maxlength;

      switch(spanName){

        case "个人简介":  this.type="textarea";  this.activeTwo=true; this.errmessage=errmessage;  this.message=message1 ;  this.dialogVisibleEmail=true; break;

        case "用户邮箱":  this.type="text";this.errmessage=errmessage1;  this.dialogVisibleEmail=false;
        
        this.message="验证码请在已绑定的邮箱获取"; this.activeTwo=false;

        break;
      }

      this.dialogVisible = true;
    },

    input(value){

       this.activeTwo=value.length==0?true:false;
       
    },

    sendEmail()
    {
       const self = this;

       self.statusMsg = ''

       let count = 60;

       SendEmail(this.User.email).then(res=>{


            self.key=res.rspdata.key;

            self.$message({message: '发送验证码成功', type: 'success' });

            self.statusMsg = `${count--}`

            self.timerid = setInterval(function() {

            self.statusMsg = `${count--}`

            if (count === 0) {
                
              clearInterval(self.timerid);

              self.statusMsg ="";

              self.key="";

              this.$message.error('验证码已过期,重新获取'); 
            }

            }, 1000)
        }).catch(err => {this.$message.error('服务器已断开,请联系管理员'); })

    },

    Save(){

      if(this.activeTwo){return}

      this.loading=true;

      var IntroductionData=new Object();

      if( this.type=="textarea")
      {

        IntroductionData.Introduction=this.content

        IntroductionUpdateuction(IntroductionData).then(res=>{

          this.User.style=res.rspdata;

          this.content=res.rspdata; this.loading = false; this.dialogVisible=false; this.activeTwo=true;

          this.$message({message: '更新个人简介成功', type: 'success' });

        }).catch(error=>{

          this.loading = false; 
        })
      }

      else
      {
         IntroductionData.append("Code",this.EamilCode);

         IntroductionData.Key=this.key

         IntroductionData.Email=this.content

         IntroductionData.Code=this.EamilCode

        IntroductionUpdateuCode(IntroductionData).then(res=>{

          this.User.email=this.content;

          this.loading = false; this.dialogVisible=false;

          this.$message({message: '更新邮箱成功', type: 'success' });

        }).catch(error=>{ this.loading = false;  })
         
      }

    }
  },

  watch: {
    // 监听data中弹层状态
    dialogVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";

        document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ""; // 出现滚动条

        document.removeEventListener("touchmove", preD, { passive: false });
      }
    }
  }
};
</script>


<style lang="scss">
.el-dialog {
  position: relative;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 50%;
  z-index: 5000;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}

.el-dialog__header {
  display: flex;

  flex-direction: row;

  .el-dialog__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: black;
    margin-bottom: 24px;
  }

  justify-content: space-between;
}

.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 0px 25px 30px;
}
</style>

<style scoped lang="less">
#PersonalUser {
  height: 100%;
font-size: 18px;
  font-family: miranafont, "Hiragino Sans GB", STXihei, "Microsoft YaHei",
    SimSun, sans-serif;

  width: 100%;


  font-weight: 300;

  background-size: cover;

  .UserCenter__profile-edit-modal__btn-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.Components-UI__Button {
font-size: 18px;
  font-family: "PingFang SC","SF Pro SC","SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 80px;
    height: 36px;
    padding: 9px 0;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  color: black;
    cursor: pointer;
}

 .status {

   font-size: 15px;
   margin-left: 10px;
   font-weight: bold;
   color: #f7acbc;
  }

.Components-UI__Button:hover {
	
	background: #cdcdcd;
} 

.default {
   color: black;
    border: 1px solid #cdcdcd;
}

 .dangerous {
    margin-left: 12px;
}

.dangerous  {
    background-image: linear-gradient(254deg, #ff3183, #ff102b);
}

.dangerous:hover {
	
	background: blueviolet;
} 

.disabled {
    cursor: not-allowed;
    color: #fff !important;
    background: #cdcdcd !important;
}


  .xgpc-input__wrapper {
    position: relative;
    background-color: #f6f6f6;
  }

  .xgpc-input__error {
    margin-top: 8px;
    font-size: 15px;
    line-height: 17px;
    color: #f7acbc;
}

.input__wrapper_email{

  position: relative;

}

  .xgpc-input__text {
    margin-top: 10px;
    font-size: 15px;
    line-height: 17px;
   color: black;
    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .content {
    display: flex;

    flex-direction: column;

    overflow: auto;

    margin-top: 70px;

    ._content {
      display: flex;

      flex-direction: row;

      justify-content: space-around;

      height: 70px;

      margin-top: 5px;

      text-align: center;

      border-bottom: 1px solid #ddd;

      align-items: center;

      ._content_spanLeft {
        display: flex;

        flex-direction: row;

        justify-content: flex-start;

        width: 70%;

        text-align: center;

        align-items: center;

        span {
          font-size: 15px;

          text-align: center;

          display: block;

          float: left;

          padding: 5px 20px;

          overflow: hidden;

          text-overflow: ellipsis;

          -o-text-overflow: ellipsis;

          white-space: nowrap;
        }

        ._content_spanLeft_Img {
          width: 70px;

          height: 70px;

          border-radius: 50px;

          padding: 0px 20px;

          img {
            width: 70px;
            height: 70px;
            padding-bottom: -10px;
            border-radius: 50px;
          }
        }
      }
    }
  }
}
</style>

