<template>
  <div id="watch-history" v-loading="historyloading">
    <el-row type="flex" class="row-bg" justify="start">
      <el-col :span="5">
        <el-input
          placeholder="关键字查询"
          v-model="PageModel.Title"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="1">
        <el-button
          icon="el-icon-search"
          style="margin-left: 20px"
          type="primary"
          @click="Lquery"
          circle
        ></el-button>
      </el-col>

      <el-col :span="1">
        <el-button
          icon="el-icon-delete"
          style="margin-left: 30px"
          type="danger"
          @click="del(Data, $event, false)"
          circle
        ></el-button>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="20" class="row-bg">
      <el-divider content-position="left">{{
        Typeinfo == 1 ? "历史记录" : "收藏次数"
      }}</el-divider>
    </el-row>

    <el-row>
      <div class="Henad_items">
        <div v-if="Data.length" class="Henad_items_div">
          <div>
          <el-card
            v-for="value in Data"
            :key="value.rowindex"
            shadow="hover"
            :body-style="{ padding: '10px' }"
            @click.native="article(value)"
          >
            <img :src="value.url" class="image" />
            <div style="margin-top: 20px">
              <span>{{ value.title }}</span>
              <div class="times_item_Save">
                <time class="time">{{ value.create_time }}</time>
                <el-button
                  @click="del(value, $event, true)"
                  type="text"
                  class="button"
                  >删除</el-button
                >
              </div>
            </div>
          </el-card>
          </div>
        </div>
        <Empty style="height: 200px" v-else></Empty>
        <el-divider v-if="Data.length"></el-divider>
        <div v-if="Data.length">
          <!--加载更多-->
          <div class="more">
            <div class="more-btn" @click="loadMore">加载更多</div>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>


<script>
import Empty from "../components/Empty";

import { IntroductionBlog, IntroductionBlogDel } from "../api/Resources";

export default {
  components: {
    Empty,
  },
  props: ["Typeinfo"], //通过props获取父组件传递过来的值

  data() {
    return {
      historyloading: false,
      Data: [],
      PageModel: {
        PageNum: 0,
        PageSize: 5,
        Total: 0,
        PageIndex: 1,
        Title: "",
        activeIndex: 1,
      },
    };
  },

  computed: {
    BlogClass() {
      return this.$store.getters.BlogClass;
    },
  },
  methods: {
    IntroductionBlogPage() {
      this.PageModel.activeIndex = this.Typeinfo == 4 ? 2 : this.Typeinfo;
      this.historyloading = true;
      IntroductionBlog(this.PageModel)
        .then((res) => {
          this.Data = this.Data.concat(res.rspdata.data);
          this.PageModel.PageNum = res.rspdata.pagenum;
          this.PageModel.PageSize = res.rspdata.pagesize;
          this.PageModel.Total = res.rspdata.total;
          this.PageModel.PageIndex = res.rspdata.pageindex;
          this.historyloading = false;
        })
        .catch((error) => {
          this.historyloading = false;
        });
    },

    article(data) {
      let routeData = this.$router.resolve({ path: "/article/" + data.id });
      window.open(routeData.href, "_blank");
    },

    del(vale, e, isdel) {
      e.stopPropagation();

      this.$confirm("是否清除该查看历史记录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var pams = { Isdel: isdel,BlogUserType:this.PageModel.activeIndex  };
          if (isdel) {
            pams.TaskId = [vale.hid];
          }
          this.historyloading = true;
          IntroductionBlogDel(pams)
            .then((res) => {
              if (isdel) {
                var index = this.Data.indexOf(vale);
                if (index > -1) {
                  this.Data.splice(index, 1);
                }
              } else {
                this.Data = [];
              }
              this.historyloading = false;
            this.$message({
          message: '恭喜你，删除成功',
          type: 'success'
        });
            })
            .catch((error) => {
              this.historyloading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    loadMore() {
      this.PageModel.PageIndex++;
      this.IntroductionBlogPage();
    },
    Lquery() {
      this.PageModel.PageIndex = 1;
      this.Data = [];
      this.IntroductionBlogPage();
    },
  },

  mounted() {
    this.IntroductionBlogPage();
  },
};
</script>


<style scoped lang="less">
#watch-history {
  height: 100%;
  width: 100%;
  font-weight: 300;
  background-size: cover;
  height: 100%;

  .Henad_items {
    width: 100%;
    .time {
      font-size: 13px;
      color: #999;
    }

    .Henad_items_div {
      width: 100%;
      display: flex;
      cursor: pointer;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      >div {
     
        display: flex;
        cursor: pointer;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space: nowrap;
        width: 240px;
        height: 24px;
        display: block;
        font-size: 15px;
      }

      .image {
        width: 200px;
        height: 170px;
        display: block;
        border-radius: 50px;
        margin: 0 auto;
        transform: scale(1);
        transition: transform 1s ease 0s;
      }
      .image:hover {
        transform: scale(1.05);
      }

      .times_item_Save {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px auto;
        text-align: center;
      }
    }
  }

  .more {
    margin: 50px 0;
    .more-btn {
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #adadad;
      border: 1px solid #adadad;
      border-radius: 20px;
      margin: 0 auto;
      cursor: pointer;
      &:hover {
        color: #8fd0cc;
        border: 1px dashed #8fd0cc;
      }
    }
  }
}
</style>