<template>
  <div class="Personal">
    <div class="Main">
      <div class="Main_Right">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane name="first">
            <span
              style="
                font-size: 16px;
                font-weight: 500;
                line-height: 10px;
                text-align: center;
              "
              slot="label"
            >
              <i class="el-icon-platform-eleme"></i> 历史记录
            </span>
            <transition name="el-zoom-in-center">
              <history v-if="activeName == 'first'" :Typeinfo="1"></history>
            </transition>
          </el-tab-pane>
          <el-tab-pane name="second">
            <span
              style="
                font-size: 16px;
                font-weight: 500;
                line-height: 10px;
                text-align: center;
              "
              slot="label"
            >
              <i class="el-icon-message-solid"></i> 我的消息
            </span>
            <el-row style="padding: 20px" type="flex" class="row-bg" justify="start">
              <el-col :span="5">
                <el-input v-model="UserMessagePageModel.Title" placeholder="关键字查询" clearable></el-input>
              </el-col>
              <el-col :span="1">
                <el-button icon="el-icon-search" style="margin-left: 20px" type="primary" circle></el-button>
              </el-col>
            </el-row>

            <el-divider content-position="left">
              评论列表
              <i
                style="cursor: pointer"
                title="刷新"
                @click="GerUserMessageinfo"
                class="el-icon-refresh"
              ></i>
            </el-divider>

            <div v-loading="replysloading">
              <div v-for="item in Data" :key="item.id">
                <replys @submitReply="submitReply" :Data="item"></replys>
              </div>
            </div>

            <el-pagination
              v-if="UserMessagePageModel.Total != 0"
              style="padding: 50px 16px 0; margin-bottom: 32px; float: right"
              :current-page.sync="UserMessagePageModel.PageIndex"
              :page-size="UserMessagePageModel.PageSize"
              :total="UserMessagePageModel.Total"
              @current-change="BlogPagehandleCurrentChange"
            ></el-pagination>
          </el-tab-pane>
          <el-tab-pane name="third">
            <span
              style="
                font-size: 16px;
                font-weight: 500;
                line-height: 10px;
                text-align: center;
              "
              slot="label"
            >
              <i class="el-icon-s-promotion"></i> 我的收藏
            </span>
            <el-collapse-transition>
              <history v-if="activeName == 'third'" :Typeinfo="4"></history>
            </el-collapse-transition>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { GerUserMessage } from "../api/Resources";

import Banner from "@/components/banner";

import personalUser from "@/components/User";

import replys from "@/components/Reply";

import history from "@/components/watch-history";

import sectionTitle from "@/components/section-title";

export default {
  name: "Personal",
  components: {
    Banner,
    personalUser,
    replys,
    history,
    sectionTitle
  },

  data() {
    return {
      Data: [],
      isCollapse: false,
      activeIndex2: 3,
      replysloading: false,
      activeName: "first",
      UserMessagePageModel: {
        PageNum: 0,
        PageSize: 10,
        Total: 0,
        PageIndex: 1,
        Data: [],
        Title: ""
      }
    };
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      this.activeIndex2 = key;
    },
    islogin() {
      if (!this.login) {
        //this.$notify({ title: '警告',message: '用户已过期,请重新登录',type: 'warning'});

        setTimeout(() => {
          this.$router.push("/");
        }, 1500);
      }
    },
    GerUserMessageinfo() {
      this.replysloading = true;
      GerUserMessage(this.UserMessagePageModel).then(res => {
        this.Data = res.rspdata.data;
        this.UserMessagePageModel.PageNum = res.rspdata.pagenum;
        this.UserMessagePageModel.PageSize = res.rspdata.pagesize;
        this.UserMessagePageModel.Total = res.rspdata.total;
        this.UserMessagePageModel.PageIndex = res.rspdata.pageindex;
        this.$nextTick(() => {
          this.replysloading = false;
        });
      });
    },
    BlogPagehandleCurrentChange(val) {
      this.UserMessagePageModel.PageIndex = val;
      this.GerUserMessageinfo();
    },
    submitReply() {
      this.UserMessagePageModel.PageIndex = 1;
      this.GerUserMessageinfo();
    }
  },

  computed: {
    login() {
      return this.$store.getters.islogin;
    },
    User() {
      return this.$store.getters.User;
    }
  },

  mounted() {
    this.islogin();
    this.GerUserMessageinfo();
  },
  watch: {
    activeIndex2(data) {
      if (data == 3) {
      }
    },
    login: function(newVal, oldVal) {
      if (newVal == false) {
        setTimeout(() => {
          this.$router.push("/");
        }, 1500);
      }
    }
  }
};
</script>



<style scoped lang="less">
.Main {
  width: 100%;
  margin: 120px auto 20px;
  .Main_Right {
    padding: 0px 100px;
    width: 100%;
  }
}
</style>
