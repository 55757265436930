<template>
  <div>
    <div class="hide block">
      <div class="MessageCenterV3__content__link">
        <div class="component-avatar MessageCenterV3__content__avatar">
          <span class="component-avatar__inner">
            <img
              class="BU-MagicImage"
              :src="Data.usera.fromuseravatar"
              alt=""
            />
          </span>
        </div>
        <div class="MessageCenterV3__content__middle">
          <div class="MessageCenterV3__content__middle__desc">
            <span class="MessageCenterV3__content__middle__desc-name">{{
              Data.usera.username
            }}</span>
          </div>

          <div class="MessageCenterV3__content__middle__time">
            <span>{{ Data.create_time | formatDate }}</span>
            <span style="margin-left: 10px">点赞</span>
            <span style="color: red">({{ Data.count }})</span>人
          </div>

          <div class="MessageCenterV3__content__middle__time">
            <span style="margin-left: -10px">【博客标题】</span>
            <router-link target="_blank" :to="`/article/${Data.blogid}`">
              {{ Data.title }}
            </router-link>
          </div>

          <section-title>
            <div
              @click="content__link"
              class="MessageCenterV3__content__middle__cont"
            >
              <div class="truncatedText">
                {{ Data.content }}
              </div>
            </div>
          </section-title>
        </div>
      </div>
    </div>

    <el-dialog
      center="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <div v-loading="Likeloading" style="height: auto; box-sizing: border-box">
        <sectionTitle>
          <div class="xgpc-input__text">我的评论</div>
          <div v-html="Data.content" class="xgpc-input__error"></div>
        </sectionTitle>

        <sectionTitle>
          <div class="xgpc-input__text">
            点赞(<span style="color: red">{{ Messagelike.count }}</span
            >)人
          </div>
          <div class="xgpc-input__textx">
            <div
              v-for="item in Messagelike.data"
              :key="item.id"
              class="component-avatar MessageCenterV3__content__avatar"
              style="width: 50px; height: 50px"
            >
              <span class="component-avatar__inner"
                ><picture>
                  <img
                    class="BU-MagicImage"
                    :src="item.loginviewmodel.avatar"
                    alt=""
                    style="
                      width: 100%;
                      height: 100%;
                      transition: transform 0.3s ease 0s;
                      object-fit: cover;
                    " /></picture
              ></span>
            </div>
          </div>
        </sectionTitle>

        <sectionTitle>
          <div class="xgpc-input__text">博客标题</div>
          <div class="xgpc-input__textx">
            <router-link target="_blank" :to="`/article/${Messagelike.blogid}`">
              {{ Messagelike.title }}
            </router-link>
          </div>
        </sectionTitle>

     
          <witMsg
            ref="initwitmsg"
            :istitle="false"
            @submitReply="submitReply"
            :messagecount="0"
          ></witMsg>
        <div class="UserCenter__profile-edit-modal__btn-group">
          <div
            @click="dialogVisible = false"
            class="Components-UI__Button default"
          >
            取消
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import sectionTitle from "@/components/section-title";
// import commentMessageEditor from "comment-message-editor";
import { GerMessagelike, AddMessage } from "../api/Resources";
import witMsg from "@/components/wtMsg";
export default {
  name: "Reply",
  props: ["Data"],
  components: {
    sectionTitle,
    witMsg,
  },

  data() {
    return {
      activeName: "1",
      dialogVisible: false,
      PdialogVisible: false,
      DdialogVisible: false,
      title: "回复详细",
      conheight: {
        height: "",
      },
      Likeloading: false,
      Messagelike: [],
    };
  },

  computed: {
    User() {
      return this.$store.getters.User;
    },
    login() {
      return this.$store.getters.islogin;
    },
  },

  created() {},

  methods: {
    content__link() {
      this.$nextTick((res) => {
        this.dialogVisible = true;
        this.Likeloading = true;
        GerMessagelike(this.Data.id).then((res) => {
          this.Messagelike = res.rspdata;
          this.Likeloading = false;
        });
      });
    },

    Pcontent__link() {
      this.$nextTick((res) => {
        this.PdialogVisible = true;
        this.Likeloading = true;
        GerMessagelike(this.Data.id).then((res) => {
          this.Messagelike = res.rspdata;
          this.Likeloading = false;
        });
      });
    },
    submitReply(textareatest) {
      if (!this.login) {
        this.$message({
          message: "警告哦，请先登录",
          type: "warning",
        });

        this.$store.state.Popup = true;

        return;
      }
      var obj = new Object();
      obj.BlogId = this.Data.blogid;
      obj.Content = textareatest;
      obj.ParentId = this.Data.id;
      obj.Like_count = 0;
      obj.MessageUserId = this.Data.usera.id;
      this.Likeloading = true;
      AddMessage(obj)
        .then((res) => {
          this.$refs.initwitmsg.deletesave(); //清除文本框信息
          this.$message({ message: "恭喜你回复成功", type: "success" });
          this.loading = false;
          this.dialogVisible =false;
          this.$emit("submitReply", true);//通知刷新列表
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
  watch: {
    // 监听data中弹层状态
    dialogVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";

        document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ""; // 出现滚动条

        document.removeEventListener("touchmove", preD, { passive: false });
      }
    },
    PdialogVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";

        document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ""; // 出现滚动条

        document.removeEventListener("touchmove", preD, { passive: false });
      }
    },
    DdialogVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";

        document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ""; // 出现滚动条

        document.removeEventListener("touchmove", preD, { passive: false });
      }
    },
    "$refs.editor.$refs.emojiPicker.pickerVisible": {
      deep: true,
      handler: function (newV, oldV) {
        console.log("watch中：", newV);
      },
    },
  },
};
</script>


<style scoped lang="less">
.hide {
  display: none;
}
.block {
  display: block;
}
.MessageCenterV3__content__link {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 10px;
  height: auto;
}
.component-avatar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #f9f9f9;
}
.MessageCenterV3__content__avatar {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
}

.user-nameadmin {
  background: Crimson;
  color: white;
  padding: 1px;
  margin: 10px;
}
.MessageCenterV3__content .component-avatar .component-avatar__inner {
  cursor: pointer;
}

.BU-MagicImage {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease 0s;
  object-fit: cover;
}
.BU-MagicImage :hover {
  transform: scale(2.05, 2.05);
}
.Components-UI__Button {
  font-size: 18px;
  width: 80px;
  height: 36px;
  padding: 9px 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.UserCenter__profile-edit-modal__btn-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.component-avatar .component-avatar__inner img {
  border-radius: 50%;
  vertical-align: top;
}
.MessageCenterV3__content__middle {
  flex-grow: 1;
  margin-left: 12px;
}
.MessageCenterV3__content__middle__desc {
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 4px;
}
.MessageCenterV3__content__middle__desc-name {
  font-weight: 500;
  color: blue;
}
.MessageCenterV3__content__middle__desc-info.desc-info-space {
  margin-left: 8px;
}
.MessageCenterV3__content__middle__time {
  font-size: 15px;
  line-height: 40px;
  color: #999;
}
.MessageCenterV3__content__middle__cont {
  margin-top: 8px;
  color: #1d1d1d;
  cursor: pointer;
  position: relative;
}

.MessageCenterV3__content__middle__cont .truncatedText {
  line-height: 30px;
}
.MessageCenterV3__content__right {
  position: relative;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
}
.disabled {
  cursor: not-allowed;
  color: #fff !important;
  background: #cdcdcd !important;
}

.dangerous {
  margin-left: 12px;
}

.xgpc-input__error {
  margin-top: 8px;
  font-size: 15px;
  line-height: 17px;
  color: #f7acbc;
}

.dangerous {
  background-image: linear-gradient(254deg, #ff3183, #ff102b);
}

.default {
  color: #1d1d1d;
  border: 1px solid #cdcdcd;
}

.xgpc-input__text {
  margin-top: 10px;
  font-size: 15px;
  line-height: 17px;
  color: #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.xgpc-input__textx {
  margin-top: 10px;
  font-size: 15px;
  line-height: 17px;
  color: #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.xgpc-input__textx:hover {
  color: #ff3183;
}
.xgpc-input__text:hover {
  color: #ff3183;
}
.long_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-y: auto;
  height: 100px;
}
</style>

